
import { useLocation } from "@reach/router";
import QualityIssuesOutput from "components/PreTranslation/QualityIssuesOutput";
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import CopyLink from "assets/copu_link_icon.svg";
import * as XLSX from 'xlsx';
import DownloadSection from "utils/resultDownload";


const SourceOutput = ({ result,error,shareresult,tooltipText}) => {
  const [tooltipContent,setTooltipContent] = useState('copy')
    const [selectedFormat, setSelectedFormat] = useState("Download CSV");
    const downloadOutputFormat = ["Download CSV", "Download XLS"];

  const location = useLocation();

  const url = location.href;
 const languagecode = result?.meta?.source_language_code

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    setTooltipContent('Link copied');
    
    
    setTimeout(() => {
      setTooltipContent('Copy');
    }, 2000);
  }
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadText();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };
  const output = result?.result;
  const downloadText = () => {
    // Prepare data for CSV export from `for_review`
    const forReviewData = output?.for_review?.map((item) => ({
      type: item.type || '',
      description: item.items[0]?.description || "No issues found.",
    })) || [];

    // Prepare data for CSV export from `dnt_terms`
    const dntTermsData = output?.dnt_terms?.map((item) => ({
      term: item.term || '',
      explanation: item?.explanation || "No explanation provided.",
    })) || [];

    // Combine both data sets into one for CSV formatting
    const combinedData = [
      ...forReviewData.map(item => ({
        type: item.type,
        description: item.description,
        term: '',
        explanation: '',
      })),
      ...dntTermsData.map(item => ({
        type: '',
        description: '',
        term: item.term,
        explanation: item.explanation,
      }))
    ];

    // Create CSV string with headers
    let csv = "Type,Description,Term,Explanation\n";

    // Append rows to CSV string
    combinedData.forEach((row) => {
      const escapeQuote = (str) => str.replace(/"/g, '""');  // Handle quotes in CSV
      csv += `"${escapeQuote(row.type)}","${escapeQuote(row.description)}","${escapeQuote(row.term)}","${escapeQuote(row.explanation)}"\n`;
    });

    // Create a Blob with the CSV content
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    
    if (link.download !== undefined) { // For browsers that support download attribute
      link.href = URL.createObjectURL(blob);
      link.download = "quality_issues.csv";
      document.body.appendChild(link); // Append link to the body (necessary for some browsers)
      link.click();
      document.body.removeChild(link);
    }
  };

  
  const downloadXLS = () => {
    const dataForReview = [
      {
        type: "Type",
        description: "Description",
      },
      ...output?.for_review?.map((item) => ({
        type: item.type,
        description: item.items[0]?.description || "No issues found.",
      })),
    ];
  
    const dataDntTerms = [
      {
        term: "Key Term",
        explanation: "Consideration",
      },
      ...output?.dnt_terms?.map((item) => ({
        term: item.term,
        explanation: item?.explanation || "No explanation available",
      })),
    ];

    const combinedData = [
      { section: "Potential Quality Concerns"}, 
      ...dataForReview,

      { section: "Important Terms for Review" }, 
      ...dataDntTerms,
    ];

    const ws = XLSX.utils.json_to_sheet(combinedData, { header: ["section", "type", "description", "term", "explanation"] });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Quality Issues Report");

    XLSX.writeFile(wb, "Quality_Issues_Report.xlsx");
  };
  
 return (
    <div className="w-full max-w-7xl mx-auto px-4 font-opensans py-12 lg:py-24">
      <div className="flex justify-between items-center pb-3 border-b border-[#C7C7C7]">
        <p className="text-lg text-gray26 font-bold font-opensans">
          Results
        </p>
        <div className="flex gap-3">
        <button 
          className="flex items-center gap-2"
          data-tooltip-id="linkcopy-tooltip"
          data-tooltip-content={tooltipText || tooltipContent}
          onClick={shareresult ? shareresult :  copyUrl }
        >
          <img src={CopyLink} alt="/copyicon"/>
        
          <span className="text-sm font-opensans text-[#5B93FF] font-semibold">
            Copy link
          </span>
        </button>
        <DownloadSection
            handleDownload={handleDownload}
            selectedFormat={selectedFormat}
            handleFormatChange={handleFormatChange}
            downloadOutputFormat={downloadOutputFormat}
            text="Download Full Report"
            isSource={true}
          />
          </div>
      </div>
      <Tooltip
          id="linkcopy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
   
      <QualityIssuesOutput
            header="Quality Issues Identified"
            output={ output}
            languagecode={languagecode}
            ispretranslationterm ={true}
            ispretranslation={true}
          />
   
    </div>
  );
};

export default SourceOutput;
